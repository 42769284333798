<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="apiLogs"
                          columnsPrefix="logs.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="logs.title.api_logs"
                >
                    <template #cell(offering_id)="row">
                        <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.offering_id)"
                           href="#">{{ row.item.offering.number }}</a>
                    </template>

                    <template #cell(data)="row">
                        {{ JSON.stringify(row.item.data).substring(0, 50) }}<span
                        v-if="JSON.stringify(row.item.data).length>50">... </span>
                        <b-button @click="$root.copyToClipboard(JSON.stringify(row.item.data))"
                                  size="sm"
                                  variant="info"
                                  class="ml-2"
                        >
                            <font-awesome-icon icon="copy"/>
                        </b-button>
                    </template>

                    <template #cell(actions)="row">
                        <b-button size="sm"
                                  @click="$root.$children[0].openModal('json-modal', {data: row.item.data})"
                                  variant="info"
                                  class="mr-2"
                        >
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'ApiLogs',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('ApiLogs', ['getApiLogsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'ApiLogs/fetchApiLogs', 'getApiLogsTable');
        },
    },
}
</script>